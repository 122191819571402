import _ from "lodash";
import React, { useEffect } from "react";
import { Button } from "../Button/Button";
import { useHistory } from "react-router-dom";

export default function Bet() {
  const random = Math.floor(Math.random() * 9) + 1;
  const history = useHistory();
  let ids = JSON.parse(localStorage.getItem("makeBetIds"));
  ids = ids ? ids : [];
  const GoBack = () => {
    history.goBack();
  };
  useEffect(() => {
    ids.forEach((id) => {
      const player = JSON.parse(localStorage.getItem(id.id));
      if (player.Bet === random) {
        localStorage.setItem(player.id, JSON.stringify({ ...player, Wins: player.Wins + 1, Fate: "Win", Winnings: player.Price * 2 }));
      } else {
        localStorage.setItem(player.id, JSON.stringify({ ...player, Lost: player.Lost + 1, Fate: "Lost", Winnings: 0 }));
      }
    });
    return () => 0;
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <div className="flex items-center justify-around space-x-4 mt-8">
        <div className="flex flex-col w-4/12 sm:w-3/12 md:w-2/12 lg:w-1/12">
          <Button text="Back" onClick={GoBack} />
        </div>
        <span className="flex w-10 h-10 sm:w-12 sm:h-12 items-center justify-center text-yellow-500 text-2xl rounded-full bg-black border border-yellow-500">{random}</span>
      </div>
      <div className="flex flex-wrap items-center justify-center">
        {ids.map((id, index) => {
          return <Player key={index} id={id.id} random={random} />;
        })}
      </div>
    </>
  );
}
const Player = ({ id, random }) => {
  const row = JSON.parse(localStorage.getItem(id));
  return (
    <div className={`flex flex-col m-4 border-2 shadow-lg rounded-md ${random === row.Bet ? "border-green-700" : "border-red-500"} space-y-2 bg-gray-100 px-2 pt-2 w-full sm:w-4/12 md:w-3/12 lg:4/12`}>
      <div className="flex space-x-2">
        <div className="flex">
          <img src={row["Profile Image"]} alt={row.Name} className={`w-10 h-10 sm:w-12 sm:h-12 rounded-full shadow-sm border-4 ${_.shuffle(["border-indigo-300", "border-blue-300", "border-red-300"])[0]}`} />
        </div>
        <div className="flex flex-col">
          <span className="text-base font-semibold text-black">{row.Name}</span>
          <span className="text-sm font-normal text-gray-700">Level {Math.ceil((row.Wins + row.Lost) / 10)}</span>
        </div>
      </div>
      <div className="flex space-x-2">
        <div className="flex space-x-1">
          <span>🏆</span>
          <span>{random === row.Bet ? row.Wins + 1 : row.Wins}</span>
        </div>
        <div className="flex space-x-1">
          <span>🎰</span>
          <span>{row.Bet}</span>
        </div>
        <div className="flex space-x-1">
          <span>💰</span>
          <span>{row.Price}</span>
        </div>
      </div>
      <div className={`flex items-center justify-center text-white text-base font-bold rounded-t-md mx-8 ${random === row.Bet ? "bg-green-700" : "bg-red-500"}`}>{random === row.Bet ? "Winner" : "Lose"}</div>
    </div>
  );
};
