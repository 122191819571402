import React from "react";
import TableBody from "./TableBody";
import { TableHeader } from "./TableHeader";
import {Pagination} from "../Pagination/Pagination";
import Search from "../Search/Search";
export default function Table() {
  return (
    <div className="mt-4 sm:my-2 md:mx-4 lg:mx-4 xl:mx-20 2xl:mx-60  rounded-t-2xl sm:rounded-none">
      <Search/>
      <TableHeader />
      <TableBody />
      <Pagination />
    </div>
  );
}
