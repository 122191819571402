import React from "react";

export const Nothing = () => {
  return (
    <div className={`flex mx-2 sm:mx-0 py-2 space-y-2 bg-white flex-col justify-center items-center`}>
      <svg className="h-16 w-16 fill-current text-purple-700" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
        <path fillRule="evenodd" d="M13.477 14.89A6 6 0 015.11 6.524l8.367 8.368zm1.414-1.414L6.524 5.11a6 6 0 018.367 8.367zM18 10a8 8 0 11-16 0 8 8 0 0116 0z" clipRule="evenodd" />
      </svg>
      <h1>Sorry nothing to show 🙇</h1>
    </div>
  );
};
