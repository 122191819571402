import React from "react";
import { selectedPlayers as selectedPlayersAtom } from "../../Atoms";
import { useRecoilState } from "recoil";
import _ from "lodash";
export const TableRow = ({ row }) => {
  row = JSON.parse(localStorage.getItem(row.id));
  const [selectedPlayers, setSelectedPlayers] = useRecoilState(selectedPlayersAtom);
  const handlePlayerSelection = (r) => {
    if (_.some(selectedPlayers, ["id", r.id])) setSelectedPlayers(selectedPlayers.filter((i) => i.id !== r.id));
    else if (selectedPlayers.length < 9) setSelectedPlayers([...selectedPlayers, r]);
  };
  return (
    <div onClick={() => handlePlayerSelection(row)} className="w-full py-4 sm:py-2 flex items-center justify-around border-b border-l border-r bg-white hover:bg-indigo-100 hover:shadow-xl cursor-pointer ">
      <div className="w-1/12 flex items-center justify-center cursor-not-allowed">
        <input checked={_.some(selectedPlayers, ["id", row.id])} onChange={() => handlePlayerSelection(row)} disabled={selectedPlayers.length >= 9 && !_.some(selectedPlayers, ["id", row.id])} className="form-tick appearance-none h-5 w-5 border border-gray-300 rounded-full checked:bg-indigo-600 checked:border-transparent focus:outline-none" type="checkbox" value={row.id} />
      </div>
      <span className="w-2/12 font-medium text-gray-700">{row.Name}</span>
      <span className="w-1/12 hidden lg:table-cell text-center font-medium text-blue-700">{Math.ceil((row.Wins + row.Lost) / 10)}</span>
      <span className="w-2/12 flex items-center justify-center">
        <img src={row["Profile Image"]} alt={row.Name} className={`w-10 h-10 sm:w-12 sm:h-12 rounded-full shadow-sm border-4 ${_.shuffle(["border-indigo-300", "border-blue-300", "border-red-300"])[0]}`} />
      </span>
      <span className="w-2/12 lg:w-1/12 text-center font-medium text-yellow-600">{row.Bet}</span>
      <span className="w-1/12 hidden lg:table-cell text-center font-medium text-purple-600">{row.Wins}</span>
      <span className="w-1/12 hidden lg:table-cell text-center font-medium text-yellow-800">{row.Lost}</span>
      <span className="w-1/12 hidden lg:table-cell text-center font-medium text-green-800">{row.Winnings}</span>
      <span className="w-1/12 hidden lg:table-cell text-center font-medium text-yellow-800">{row.Fate}</span>
      <span className="w-2/12 lg:w-1/12 text-center font-medium text-gray-900">{row.Price}</span>
    </div>
  );
};
