import { useEffect } from "react";
import _ from "lodash";
import { useRecoilState, useResetRecoilState, useSetRecoilState } from "recoil";
import { searchTerm as searchTermAtom, betsQuery as betsQueryAtom, betIds as betIdsAtom } from "../Atoms";

export function useSearch() {
  const [searchTerm, setSearchTerm] = useRecoilState(searchTermAtom);
  const [betsQuery, setbetsQuery] = useRecoilState(betsQueryAtom);
  const setBetIds = useSetRecoilState(betIdsAtom);

  const resetbetsQuery = useResetRecoilState(betsQueryAtom);
  const search = () => {
    if (searchTerm.length > 0) setbetsQuery({ ...betsQuery, page: 1, searchTerm: searchTerm });
    else resetbetsQuery();
  };
  const handleOnChange = (e) => {
    if (!e.target.value) resetbetsQuery();
    setSearchTerm(e.target.value);
  };
  const handleOnClear = () => {
    setSearchTerm("");
    resetbetsQuery();
  };
  const handleOnKeyPress = (e) => {
    if (e.key === "Enter") search();
  };
  useEffect(() => {
    if (betsQuery.searchTerm) {
      const ids = JSON.parse(localStorage.getItem("ids"));
      let bets = [];
      ids.forEach((id) => {
        bets.push(JSON.parse(localStorage.getItem(id.id)));
      });
      setBetIds(
        _.filter(bets, function (o) {
          if (o.Name.toLowerCase().includes(betsQuery.searchTerm.toLowerCase())) return o.id;
        })
      );
    } else {
      setBetIds(JSON.parse(localStorage.getItem("ids")));
    }
    return () => 0;
    // eslint-disable-next-line
  }, [betsQuery.searchTerm]);
  return { search, handleOnChange, handleOnKeyPress, handleOnClear };
}
