import React from "react";
import { useRecoilValue } from "recoil";
import { searchTerm as searchTermAtom } from "../../Atoms";
import { useSearch } from "../../Hooks/useSearch";

export default function Search() {
  const { search, handleOnChange, handleOnKeyPress, handleOnClear } = useSearch();
  const searchTerm = useRecoilValue(searchTermAtom);

  return (
    <div className={`flex sticky top-0 items-center justify-between rounded-xl sm:rounded-none shadow-lg bg-white px-0 py-1 mb-2 mx-1 sm:mx-0`}>
      <input onFocus={(e) => e.target.select()} onKeyPress={handleOnKeyPress} value={searchTerm} onChange={handleOnChange} type="text" className="bg-transparent flex w-8/12 h-full focus:select-all focus:outline-none px-2 py-2 text-lg " placeholder={`Search player`} autoCapitalize="off" autoComplete="off" autoCorrect="off" spellCheck="false" />
      <span onClick={handleOnClear} className={`${searchTerm ? "flex" : "hidden"} font-medium hover:bg-gray-300 text-gray-500 font-sans text-sm  text-center rounded-full items-center justify-center h-6 w-6 cursor-pointer mr-3 fill-current`}>
        X
      </span>
      <svg onClick={() => search()} className={`h-8 w-8 cursor-pointer mr-3 fill-current text-indigo-600 `} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
        <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd" />
      </svg>
    </div>
  );
}
