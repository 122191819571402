import React from "react";
import { TableRow } from "./TableRow";
import { useRecoilValue } from "recoil";
import _ from "lodash";
import { betsQuery as betsQueryAtom, betIds as betIdsAtom } from "../../Atoms";
import { Nothing } from "./Nothing";
export default function TableBody() {
  const betIds = useRecoilValue(betIdsAtom);
  const betsQuery = useRecoilValue(betsQueryAtom);
  
  if (betIds.length > 0) {
    return <Table betIds={betIds} limit={betsQuery.limit} page={betsQuery.page} />;
  } else if (betIds.length === 0 && betsQuery.searchTerm) {
    return <Nothing />;
  } else {
    return <TableLoading limit={betsQuery.limit} />;
  }
}

const Table = ({ betIds, page, limit }) => {
  return (
    <>
      {_.take(_.drop(betIds, (page - 1) * limit), limit).map((row, index) => {
        return <TableRow row={row} key={index} />;
      })}
    </>
  );
};
const TableLoading = ({ limit }) => {
  return (
    <>
      {[...Array(limit).keys()].map((r) => {
        return (
          <div key={r} className="animate-pulse w-full py-4 sm:py-2 flex items-center justify-around border-b border-l border-r bg-white hover:bg-indigo-100 hover:shadow-xl cursor-pointer">
            <span className="w-1/12 h-2 my-2 font-medium"></span>
            <span className="w-3/12 h-2 my-2 font-medium rounded-md bg-gray-400"></span>
            <span className="w-1/12 hidden lg:table-cell h-2 my-2 font-medium rounded-md bg-gray-400"></span>
            <span className="w-2/12 h-2 my-2 font-medium rounded-md bg-gray-400"></span>
            <span className="w-2/12 sm:w-1/12 h-2 my-2 font-medium rounded-md bg-gray-400"></span>
            <span className="w-1/12 hidden lg:table-cell h-2 my-2 font-medium rounded-md bg-gray-400"></span>
            <span className="w-1/12 hidden lg:table-cell h-2 my-2 font-medium rounded-md bg-gray-400"></span>
            <span className="w-1/12 hidden lg:table-cell h-2 my-2 font-medium rounded-md bg-gray-400"></span>
            <span className="w-1/12 hidden lg:table-cell h-2 my-2 font-medium rounded-md bg-gray-400"></span>
            <span className="w-2/12 sm:w-1/12 h-2 my-2 font-medium rounded-md bg-gray-400"></span>
          </div>
        );
      })}
      <div className="bg-gray-100 h-5"></div>
    </>
  );
};
