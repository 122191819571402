import React, { useEffect } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import _ from "lodash";
import { betsQuery as betsQueryAtom, betIds as betIdsAtom } from "../../Atoms";
import { SortIcon } from "./SortIcon";
export const TableHeader = () => {
  const [betsQuery, setBetsQuery] = useRecoilState(betsQueryAtom);
  const setBetIds = useSetRecoilState(betIdsAtom);

  const SortTable = (field) => {
    if (betsQuery.sortField === field) setBetsQuery((o) => ({ ...o, sortOrder: o.sortOrder === "asc" ? "desc" : "asc" }));
    else setBetsQuery((o) => ({ ...o, sortField: field }));
  };
  useEffect(() => {
    if (betsQuery.sortField === "bet") {
      const ids = JSON.parse(localStorage.getItem("ids"));
      let bets = [];
      if (ids) {
        ids.forEach((id) => {
          bets.push(JSON.parse(localStorage.getItem(id.id)));
        });
        setBetIds(
          _.filter(_.orderBy(bets, ["Bet"], [betsQuery.sortOrder]), function (o) {
            if (o.Name.toLowerCase().includes(betsQuery.searchTerm.toLowerCase())) return o.id;
          })
        );
      }
    } else {
      const ids = JSON.parse(localStorage.getItem("ids"));
      let bets = [];
      if (ids) {
        ids.forEach((id) => {
          bets.push(JSON.parse(localStorage.getItem(id.id)));
        });
        setBetIds(
          _.filter(_.orderBy(bets, ["Price"], [betsQuery.sortOrder]), function (o) {
            if (o.Name.toLowerCase().includes(betsQuery.searchTerm.toLowerCase())) return o.id;
          })
        );
      }
    }
    return () => 0;
    // eslint-disable-next-line
  }, [betsQuery.sortField, betsQuery.sortOrder]);
  return (
    <div className="w-full flex justify-around items-center py-4 pr-2 border-b rounded-t-2xl lg:rounded-none bg-gray-50">
      <div className="w-1/12 flex items-center justify-center cursor-not-allowed">
        <span className="h-5 w-5 border border-gray-300 rounded-full"></span>
      </div>
      <div className="w-2/12 flex items-center justify-start">
        <span className="text-center font-medium">Name</span>
      </div>
      <div className="w-1/12 hidden lg:flex items-center justify-center ">
        <span className="text-center font-medium">Level</span>
      </div>
      <div className="w-2/12 flex items-center justify-center ">
        <span className="text-center font-medium">Avatar</span>
      </div>
      <div className="w-2/12 lg:w-1/12 flex items-center justify-center space-x-2 cursor-pointer" onClick={() => SortTable("bet")}>
        <span className="text-center font-medium">Bet</span>
        <SortIcon direction={betsQuery.sortOrder} active={betsQuery.sortField === "bet"} />
      </div>
      <div className="w-1/12 hidden lg:flex items-center justify-center">
        <span className="text-center font-medium">Wins</span>
      </div>
      <div className="w-1/12 hidden lg:flex items-center justify-center ">
        <span className="text-center font-medium">Lost</span>
      </div>
      <div className="w-1/12 hidden lg:flex items-center justify-center ">
        <span className="text-center font-medium">Winnings</span>
      </div>
      <div className="w-1/12 hidden lg:flex items-center justify-center ">
        <span className="text-center font-medium">Fate</span>
      </div>
      <div className="w-2/12 lg:w-1/12 flex items-center justify-center space-x-2 cursor-pointer" onClick={() => SortTable("price")}>
        <span className="text-center font-medium">Price</span>
        <SortIcon direction={betsQuery.sortOrder} active={betsQuery.sortField === "price"} />
      </div>
    </div>
  );
};
