import { useSetRecoilState } from "recoil";
import { useEffect } from "react";
import _ from "lodash";
import { betIds as betIdsAtom } from "../Atoms";
import { uuidv4 } from "./uuidv4";

export function useFetchBets() {
  const setBetIds = useSetRecoilState(betIdsAtom);

  useEffect(() => {
    (async () => {
      const ids = JSON.parse(localStorage.getItem("ids"));
      if (ids) {
        setBetIds(ids);
      } else {
        setBetIds([]);
        const betsURL = "https://s3-ap-southeast-1.amazonaws.com/he-public-data/bets7747a43.json";
        let [betsResponse] = await Promise.all([fetch(betsURL)]);
        [betsResponse] = await Promise.all([betsResponse.json()]);
        if (betsResponse) {
          if (betsResponse.length > 0) {
            betsResponse = _.map(betsResponse, (m) => ({ ...m, id: uuidv4(), Bet: parseInt(m.Bet), Price: parseInt(m.Price), Lost: 0, Wins: 0, Winnings: 0, Fate: "-" }));
            betsResponse.forEach((bet) => {
              localStorage.setItem(bet.id, JSON.stringify(bet));
            });
            const ids = _.map(betsResponse, (b) => _.pick(b, "id"));
            localStorage.setItem("ids", JSON.stringify(ids));
            setBetIds(ids);
          }
          betsResponse = null;
        }
      }
    })();
    return () => 0;
  }, [setBetIds]);
}
