import React from "react";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import { Button } from "../Button/Button";
import { selectedPlayers as selectedPlayersAtom } from "../../Atoms";
import { useRecoilValue } from "recoil";
export function SelectedPlayers() {
  const selectedPlayers = useRecoilValue(selectedPlayersAtom);
  const history = useHistory();
  const MakeBet = () => {
    if (selectedPlayers.length === 9) {
      const ids = _.map(selectedPlayers, (p) => _.pick(p, "id"));
      localStorage.setItem("makeBetIds", JSON.stringify(ids));
      history.push(`/bet`);
    }
  };
  return (
    <>
      <div className="flex flex-col scroll overflow-y-auto">
        <div className="flex items-start justify-center mt-6 ">
          <h1 className="text-4xl">🎲</h1>
          <h1 className="text-2xl text-indigo-800 font-black border-b-4 border-yellow-300">Playing 9</h1>
        </div>
        <div className="flex flex-col mt-6 mb-2 mx-2 bg-white">
          {selectedPlayers.map((row, index) => {
            return <SelectedPlayer row={row} key={index} />;
          })}
        </div>
      </div>
      <div className="flex flex-col m-2">
        <Button text="START" disabled={selectedPlayers.length !== 9} onClick={MakeBet} />
      </div>
    </>
  );
}
function SelectedPlayer({ row }) {
  return (
    <div className="flex flex-wrap justify-around p-2 shadow-sm border-l-4 border-indigo-300">
      <div className="flex items-center justify-center w-2/12">
        <img src={row["Profile Image"]} alt={row.Name} className={`w-10 h-10 sm:w-12 sm:h-12 rounded-full shadow-sm border-4 ${_.shuffle(["border-indigo-300", "border-blue-300", "border-red-300"])[0]}`} />
      </div>
      <div className="flex flex-col justify-between w-6/12">
        <span className="text-base font-semibold text-black">{row.Name}</span>
        <div className="flex space-x-2">
          <div className="flex space-x-1">
            <span>🏆</span>
            <span>{row.Wins}</span>
          </div>
          <div className="flex space-x-1">
            <span>🎰</span>
            <span>{row.Bet}</span>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-center space-x-1 w-2/12">
        <span className="text-lg">💰</span>
        <span className="text-base text-yellow-700 font-bold">{row.Price}</span>
      </div>
    </div>
  );
}
