import React from "react";
import { useRecoilValue, useRecoilState } from "recoil";
import { betIds as betIdsAtom, betsQuery as betsQueryAtom } from "../../Atoms";
export const Pagination = () => {
  const [betsQuery, setBetsQuery] = useRecoilState(betsQueryAtom);
  const betIds = useRecoilValue(betIdsAtom);
  
  const setPage = (page) => {
    if (betIds.length > 0) {
      if (page > 0) {
        if (betsQuery.page < Math.ceil(betIds.length / betsQuery.limit)) setBetsQuery({ ...betsQuery, page: betsQuery.page + page });
      } else {
        if (betsQuery.page > 1) setBetsQuery({ ...betsQuery, page: betsQuery.page + page });
      }
    }
  };
  return (
    <div className={`${betIds.length === 0 ? "hidden" : "flex"} w-full p-2 items-center justify-center bg-gray-100 sm:px-0`}>
      <div className="w-1/2 flex items-center ml-0">
        <select className=" px-4 py-2 border-1 focus:border-indigo-600 border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 mx-2" value={betsQuery.limit} onChange={(e) => setBetsQuery((o) => ({ ...o, limit: parseInt(e.target.value), page: 1 }))}>
          <option value={10}>10</option>
          <option value={20}>20</option>
          <option value={50}>50</option>
          <option value={100}>100</option>
          <option value={200}>200</option>
          <option value={500}>500</option>
        </select>
      </div>
      <div className="flex items-center justify-end space-x-2 w-1/2 mr-2">
        <button disabled={betIds.length === 0} onClick={() => setPage(-1)} className={`inline-flex ${betIds.length === 0 ? "cursor-not-allowed opacity-75 hover:bg-white" : ""} items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-purple-900 bg-white hover:text-gray-500`}>
          {"<"}
        </button>
        <p className="text-sm text-gray-700">
          <span className="font-medium"> {betsQuery.page * betsQuery.limit > betIds.length ? betIds.length : betsQuery.page * betsQuery.limit} </span>
          of
          <span className="font-medium"> {betIds.length} </span>
        </p>
        <button disabled={betIds.length === 0} onClick={() => setPage(+1)} className={`ml-3 inline-flex ${betIds.length === 0 ? "cursor-not-allowed opacity-75 hover:bg-white" : ""}  relative items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-purple-900 bg-white hover:text-gray-500`}>
          {">"}
        </button>
      </div>
    </div>
  );
};
