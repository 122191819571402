import { atom } from "recoil";
export const betsRepo = atom({
  key: "betsRepo",
  default: [],
});
export const betsQuery = atom({
  key: "betsQuery",
  default: { page: 1, limit: 10, sortField: "", sortOrder: "desc", searchField: "", searchTerm: "" },
});
export const selectedPlayers = atom({
  key: "selectedPlayers",
  default: [],
});
export const searchTerm = atom({
  key: "searchTerm",
  default: "",
});
export const betIds = atom({
  key: "betIds",
  default: [],
});