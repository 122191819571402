import React from "react";
import { Switch, Route } from "react-router-dom";
import Home from "./Components/Home/Home";
import Bet from "./Components/Bet/Bet";

export default function App() {
  return (
    <Switch>
      <Route path="/bet">
        <Bet />
      </Route>
      <Route exact path="/">
        <Home />
      </Route>
    </Switch>
  );
}
