import React from "react";
import { useFetchBets } from "../../Hooks/useFetchBets";
import Table from "../Table/Table";
import { SelectedPlayers } from "../SelectedPlayers/SelectedPlayers";

export default function Home() {
  useFetchBets();
  return (
    <div className="flex flex-col sm:flex-row">
      <main className="sm:h-screen w-full flex flex-col sm:flex-row overflow-hidden select-none">
        <aside className="w-full sm:w-5/12 lg:w-4/12 xl:w-3/12 2xl:w-2/12 flex flex-col h-full justify-between bg-gray-200 scroll overflow-y-auto" style={{ backgroundImage: "url(https://static.intercomassets.com/ember/assets/images/messenger-backgrounds/background-1-99a36524645be823aabcd0e673cb47f8.png)" }}>
          <SelectedPlayers />
        </aside>
        <div className="bg-gray-200 shadow-lg order-last md:order-1 flex-1 scroll overflow-y-auto">
          <h1 className="mt-4 sm:my-2 md:mx-4 lg:mx-4 xl:mx-20 2xl:mx-60 text-center sm:text-left text-xl text-indigo-700 font-black">Select Playing 9</h1>
          <Table />
        </div>
      </main>
    </div>
  );
}
